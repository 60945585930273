export type FileIdAndUrl = {
  id: string;
  url: string;
};

export type FileContentType = {
  contentType: string;
};

export type IFile = {
  id: string;
  fileData: string;
  file: File;
  size?: number;
};

export const isImageType = (type: string) => canAcceptType(type, ['image/']);

export const isVideoType = (type: string) => canAcceptType(type, ['video/']);

export const isPdfType = (type: string) => canAcceptType(type, ['application/pdf']);

export const isDocType = (type: string) =>
  canAcceptType(type, [
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  ]);

export const isXlsType = (type: string) =>
  canAcceptType(type, [
    'application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  ]);

export const isPptType = (type: string) =>
  canAcceptType(type, [
    'application/vnd.ms-powerpoint',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  ]);

export const isCsvType = (type: string) => canAcceptType(type, ['text/csv']);

export const isTxtType = (type: string) => canAcceptType(type, ['text/']);

export const canAcceptType = (type: string, acceptTypes: string[]) =>
  acceptTypes.some((accept) => type.startsWith(accept));
